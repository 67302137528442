import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Route, Routes as RouteWrapper, useLocation } from "react-router-dom";
import * as loadable from "react-loadable";

const AsyncMoveOfferContainer = loadable({
  loader: () => import(/* webpackChunkName: "MoveOfferContainer" */ "./containers/offers/MoveOffer"),
  loading: () => <CircularProgress />,
});

const AsyncCleanOfferContainer = loadable({
  loader: () => import(/* webpackChunkName: "CleanOfferContainer" */ "./containers/offers/CleanOffer"),
  loading: () => <CircularProgress />,
});

const AsyncColorOfferContainer = loadable({
  loader: () => import(/* webpackChunkName: "ColorOfferContainer" */ "./containers/offers/ColorOffer"),
  loading: () => <CircularProgress />,
});

const AsyncAcceptOfferContainer = loadable({
  loader: () => import(/* webpackChunkName: "ColorOfferContainer" */ "./containers/offers/AcceptOffer"),
  loading: () => <CircularProgress />,
});

const AsyncFeedbackOfferContainer = loadable({
  loader: () => import(/* webpackChunkName: "NewPartnerRegistrationContainer" */ "./containers/offers/FeedbackOffer"),
  loading: () => <CircularProgress />,
});

const AsyncNewPartnerRegistrationContainer = loadable({
  loader: () => import(/* webpackChunkName: "NewPartnerRegistrationContainer" */ "./containers/partner-registration/NewPartnerRegistration"),
  loading: () => <CircularProgress />,
});

const AsyncLegalNoticeContainer = loadable({
  loader: () => import(/* webpackChunkName: "LegalNoticeContainer" */ "./containers/legal-pages/LegalNotice"),
  loading: () => <CircularProgress />,
});

const AsyncPrivacyPolicyContainer = loadable({
  loader: () => import(/* webpackChunkName: "PrivacyPolicyContainer" */ "./containers/legal-pages/PrivacyPolicy"),
  loading: () => <CircularProgress />,
});

const AsyncAGBContainer = loadable({
  loader: () => import(/* webpackChunkName: "AGBContainer" */ "./containers/legal-pages/AGB"),
  loading: () => <CircularProgress />,
});

const AsyncNotFoundContainer = loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"),
  loading: () => <CircularProgress />,
});

const Routes: React.FC = () => {
  const location = useLocation();
  // Scroll to top if path changes
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <RouteWrapper>
      <Route path="/" element={<AsyncMoveOfferContainer />} />
      <Route path="/move" element={<AsyncMoveOfferContainer />} />
      <Route path="/move/:offerType" element={<AsyncMoveOfferContainer />} />
      <Route path="/clean" element={<AsyncCleanOfferContainer />} />
      <Route path="/clean/:offerType" element={<AsyncCleanOfferContainer />} />
      <Route path="/color" element={<AsyncColorOfferContainer />} />
      <Route path="/color/:offerType" element={<AsyncColorOfferContainer />} />
      <Route path="/offer/:guid" element={<AsyncAcceptOfferContainer />} />
      <Route path="/feedback/:guid" element={<AsyncFeedbackOfferContainer />} />
      <Route path="/new-partner/" element={<AsyncNewPartnerRegistrationContainer />} />
      <Route path="/legal-notice/" element={<AsyncLegalNoticeContainer />} />
      <Route path="/privacy-policy/" element={<AsyncPrivacyPolicyContainer />} />
      <Route path="/agb/" element={<AsyncAGBContainer />} />
      <Route path="*" element={<AsyncNotFoundContainer />} />
    </RouteWrapper>
  );
};

export default Routes;
