import React, { useEffect, useState } from "react";

import { Trans, Translation, useTranslation } from "react-i18next";
import { Link, redirect, useLocation } from "react-router-dom";
import i18next from "i18next";
import appSettings from "../../app-settings";

const Navigation: React.FC = () => {
  const [showMobileNavDropdown, setShowMobileNavDropdown] = React.useState(false);
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>("de");
  const [currentOfferType, setCurrentOfferType] = useState<string>("move");
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setCurrentOfferType("move");
        break;
      case "/move":
        setCurrentOfferType("move");
        break;
      case "/clean":
        setCurrentOfferType("clean");
        break;
      case "/color":
        setCurrentOfferType("color");
        break;
    }
  }, [location]);

  const languages = ["de", "fr", "en"];

  const handleChangeLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
    setCurrentLanguage(lang);
  };

  const renderDesktopChangeLanguageDropdown = () => {
    return (
      <div className="dropdown languageSelect">
        <div className="dropdown-selected">{currentLanguage.toUpperCase()}</div>
        <div className="dropdown-list">
          {languages.map((x) => {
            return (
              <div
                className={currentLanguage == x ? "dropdown-item active" : "dropdown-item"}
                onClick={(e) => handleChangeLanguage(x)}
                key={x}
              >
                {x.toUpperCase()}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderMobileLanguageDropdown = () => {
    var dropdownLanguages = languages.filter((x) => x != currentLanguage);

    return (
      <div className="languageSelect">
        <span className="selected">{currentLanguage.toUpperCase()}</span>
        {dropdownLanguages.map((x) => {
          return (
            <div className="selectionOptions" onClick={(e) => handleChangeLanguage(x)} key={x}>
              {x.toUpperCase()}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <header>
      <nav className="desktop">
        <div className="container">
          <div className="headerTopArea">
            <Link to="/">
              <div className="logo">JustOfferten</div>
            </Link>
            <div className="offerTypes">
              <Link to="/move">
                <button className={currentOfferType == "move" ? "moveOffer active" : "moveOffer"}>
                  {t("general.moveOffer")}
                </button>
              </Link>
              <Link to="/clean">
                <button className={currentOfferType == "clean" ? "cleaningOffer active" : "cleaningOffer"}>
                  {t("general.cleaningOffer")}
                </button>
              </Link>
              <Link to="/color">
                <button className={currentOfferType == "color" ? "colorOffer active" : "colorOffer"}>
                  {t("general.colorOffer")}
                </button>
              </Link>
            </div>
          </div>
          <div className="headerBottomArea">
            <div className="slogan">
              <p>
                <Trans>{t("general.slogan")}</Trans>
              </p>
            </div>
            <div className="menuItems">
              <div className="menuItem languageSelector">{renderDesktopChangeLanguageDropdown()}</div>
              <a href={appSettings.baseUrlPartnerApp}>
                <div className="menuItem">{t("general.partnerLogin")}</div>
              </a>
              <div className="menuItem">
                <a href="#howItWorks">{t("general.howItWorks")}</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="mobile">
        <div className="container">
          <div className="content-wrapper">
            <div className="logo">
              <Link to="/">JustOfferten</Link>
            </div>
            <button
              className={showMobileNavDropdown ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin"}
              onClick={(e) => setShowMobileNavDropdown(!showMobileNavDropdown)}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className={showMobileNavDropdown ? "mobile-dropdown-menu active" : "mobile-dropdown-menu"}>
          <div className="content-wrapper">
            <div className="container">
              <div className="offerTypes">
                <Link to="/move">
                  <button
                    className={currentOfferType == "move" ? "moveOffer active" : "moveOffer"}
                    onClick={(e) => setShowMobileNavDropdown(!showMobileNavDropdown)}
                  >
                    {t("general.moveOffer")}
                  </button>
                </Link>
                <Link to="/clean">
                  <button
                    className={currentOfferType == "clean" ? "cleaningOffer active" : "cleaningOffer"}
                    onClick={(e) => setShowMobileNavDropdown(!showMobileNavDropdown)}
                  >
                    {t("general.cleaningOffer")}
                  </button>
                </Link>
                <Link to="/color">
                  <button
                    className={currentOfferType == "color" ? "colorOffer active" : "colorOffer"}
                    onClick={(e) => setShowMobileNavDropdown(!showMobileNavDropdown)}
                  >
                    {t("general.colorOffer")}
                  </button>
                </Link>
              </div>
              <div className="subMenu">
                <div className="menuItems">
                  <a href={appSettings.baseUrlPartnerApp}>
                    <div className="menuItem">{t("general.partnerLogin")}</div>
                  </a>
                  <div className="menuItem">
                    <a href="#howItWorks"
                      onClick={(e) => setShowMobileNavDropdown(!showMobileNavDropdown)}
                    >{t("general.howItWorks")}</a>
                  </div>
                </div>
              </div>
              {renderMobileLanguageDropdown()}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
