import * as React from "react";
import Routes from "../routes";
import Footer from "../components/footer/Footer";
import BrowserNotSupported from "../components/browser-not-supported/BrowserNotSupported";
import { isIE } from "react-device-detect";
import Navigation from "../components/navigation/Navigation";

const App: React.FC = () => {
  return (
    <>
      <Navigation />
      <main>
        {isIE ? <BrowserNotSupported /> : <Routes />}
      </main>
      <Footer />
    </>
  );
};

export default App;
