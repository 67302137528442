import { LinearProgress } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const SuspenseScreen: React.FC = () => {
  return (
    <Container style={{ height: "100vh", textAlign: "center", marginTop: "200px", padding: "15px", maxWidth: "800px" }}>
        <img
          src="/public/img/logo.png"
          style={{ width: "450px", maxWidth: "50%", display: "block", margin: "0 auto", marginBottom: "25px"}}
        />
        <h2>Die Applikation wird für Sie vorbereitet, bitte warten Sie einen Moment...</h2>
        <h4>We are preparing the application, please wait a Moment...</h4>
      <LinearProgress sx={{marginTop: 5}} />
    </Container>
  );
};

export default SuspenseScreen;
