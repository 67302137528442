import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./containers/App";
import SuspenseScreen from "./containers/SuspenseScreen";
import { configAxios } from "./utils/axios";
import "./i18n";
import { Suspense } from "react";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material";

const w: any = window as any;
const devtools: any = w.__REDUX_DEVTOOLS_EXTENSION__ ? w.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;

configAxios();


const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#53599a"
    },
    error: {
      main: "#d8315b"
    },
    success: {
      main: "#5ab49c"
    }
  }
});

root.render(
  <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Suspense fallback={<SuspenseScreen />}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Suspense>
      </SnackbarProvider>
      </ThemeProvider>
);
