import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h6>{t("footer.navigation.title")}</h6>
                        <ul>
                            <li><Link to="/move">{t("general.moveOffer")}</Link></li>
                            <li><Link to="/move/disposalOffer">{t("general.disposalOffer")}</Link></li>
                            <li><Link to="/move/transportOffer">{t("general.transportOffer")}</Link></li>
                            <li><Link to="/move/pianoTransportOffer">{t("general.pianoTransportOffer")}</Link></li>
                            <li><Link to="/clean/cleanMoveOffer">{t("general.cleanMoveOffer")}</Link></li>
                            <li><Link to="/clean/maintenanceCleanOffer">{t("general.maintenanceCleanOffer")}</Link></li>
                            <li><Link to="/clean/cleanOffer">{t("general.cleanOffer")}</Link></li>
                            <li><Link to="/color">{t("general.colorOffer")}</Link></li>
                        </ul>
                    </div>
                    <div className="col-12  col-lg-6">
                        <h6>{t("footer.other.title")}</h6>
                        <ul>
                            <li><Link to="/legal-notice">{t("footer.other.impressum")}</Link></li>
                            <li><Link to="/privacy-policy">{t("footer.other.data")}</Link></li>
                            <li><Link to="/agb">{t("footer.other.agb")}</Link></li>
                        </ul>
                    </div>
                </div>
                <p className="copyright">
                    {t("footer.copyright", { year: new Date().getFullYear() })}
                </p>
            </div>
        </footer>
    )
}

export default Footer;